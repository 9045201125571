export function suffixEventId(url: string | undefined, eventId: string) {
	if (!url) {
		return "";
	}
	if (url.includes("?")) {
		if (url.endsWith("=")) {
			return url + eventId;
		} else {
			return url + `&utm_source=${eventId}`;
		}
	} else {
		return url + `?utm_source=${eventId}`;
	}
}
